.sidebar-wrapper {
  height: 1000px;
  background-color: #a7a7a7;
}

.container-sidebar {
  padding-left: 12px !important;
  padding-right: 12px !important;
  // .row-sidebarr {
  //   border-bottom: 2.5px solid #f2f2f2;
  // }
}

.sidebar {
  background-color: #fff;
  position: fixed;
  margin: 0px;
  transition: 0.5s;
  z-index: 9999;
  margin: 0px;
  transition: 0.5s;
  top: 10%;
  left: 10px;
  padding: 0px 0px 0px 0px;
  border-radius: 3px;
  bottom: 10px;
  width: 25%;
  height: fit-content;
  max-height: 75%;
  overflow-y: auto;
  transition: all 0.5s ease;

  .task-title-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.sidebar-bottom {
  background-color: #fff;
  position: fixed;
  margin: 0px;
  transition: 0.5s;
  z-index: 9999;
  margin: 0px;
  transition: 0.5s;
  left: 10px;
  bottom: 10px;
  padding: 0px 0px 0px 0px;
  border-radius: 3px;
  //width: 25%;
  //height: 13vh;
  transition: all 0.5s ease;
}

.sidebar-closed {
  left: -25%;
}

.left-10 {
  left: 10px !important
}

.icon-wrapper {
  background-color: #6f9d2f;
  width: 45px;
  height: 45px;
  padding-right: 7px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 25%;
  margin-right: 10px;
}

.task-title-icon {
  color: #fff;
  font-size: 28px;
  height: fit-content;
}

.task-title {
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 10px;
}

.close-sidebar-btn-container {
  padding-right: 0px !important;

  .close-sidebar-btn {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 12px 5px;
    border-radius: 0px 0px 0px 0px;
    height: -webkit-fill-available;
    font-size: 20px;
    // border-bottom: 1px solid #e4e4e4 !important;
    border-left: 1px solid #e4e4e4 !important;

    &:hover,
    &:active {
      background-color: #fcfcfc !important;
      color: #000 !important;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px 0px;
    }
  }
}

// .nav-tabs-custom {
//   border-bottom: 2px solid #f6f6f6 !important;
// }
.nav {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  list-style: none !important;
}

.nav-tabs-custom .nav-item {
  position: relative !important;
  color: #343a40 !important;
}

.nav-justified .nav-item,
.nav-justified>.sidebar-navlink {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .sidebar-navlink,
.nav-justified .nav-item .sidebar-navlink {
  width: 100%;
}

.nav-tabs-custom .nav-item .sidebar-navlink.active {
  color: #6f9d2f !important;
}

.nav-tabs-custom .nav-item .sidebar-navlink.active {
  color: #6f9d2f !important;
}

.sidebar-navlink {
  display: block !important;
  padding: 0.7rem 0.7rem !important;
  color: #6f9d2f !important;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out !important;
  border-radius: 0px !important;
}

.nav-tabs-custom .nav-item {
  position: relative;
  color: #343a40 !important;
}

.nav-pills>li>a,
.nav-tabs>li>a {
  font-size: 15px;
  color: #b2b2b2 !important;
  font-weight: 500 !important;
}

.sidebar-navlink:focus,
.sidebar-navlink:hover {
  color: #6f9d2f !important;
  text-decoration: none;
}

.nav-tabs-custom .nav-item .sidebar-navlink.active:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nav-tabs-custom .nav-item .sidebar-navlink::before {
  content: "";
  background: #6f9d2f;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  -webkit-transition: all 250ms ease 0s;
  transition: all 250ms ease 0s;
  -webkit-transform: scale(0);
  transform: scale(0);
  top: 0px;
}

.task-sidebar-navs-container {
  padding: 0px !important;
}

.nav-tabs .sidebar-navlink:focus,
.nav-tabs .sidebar-navlink:hover {
  // border: none;
}

.imagery-icon {
  font-size: 25px;
}

.nav-item {
  &:not(:last-child) {
    .sidebar-navlink {
      border-right: 1px solid #e4e4e4 !important;
    }
  }
}

.container-border {
  border-bottom: 1px solid #e4e4e4;
}

.row-border {}

#sidebar-toogle-button-wrapper {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 70px;
  left: 26%;
  z-index: 99999;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  .sidebar-open-icon {
    font-size: 20px;
  }

  .sidebar-close-icon {
    font-size: 20px;
  }
}

.sidebar-toogle-button-closed {
  left: 10px !important;
}

.owl-carousel.owl-loaded {
  align-items: center;
  display: flex !important;
}

.owl-carousel {
  width: 90% !important;
}

.owl-theme .owl-nav [class*="owl-"] {
  height: 100%;
  margin: 0 8px !important;
  padding: 0 12px !important;
}

.owl-item-date {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background: #ffffff75;
  font-size: 12px;
}

.owl-item-container:hover {
  cursor: pointer;

  & .item-inner-content {
    border: 3px solid #6f9d2f;
  }
}



.owl-item-container.active {
  background-color: #6f9d2f57;
}

//************checkbox index style **************//
.index-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.index-checkbox+label {
  --size: 40px;

  cursor: pointer;
  width: var(--size);
  height: calc(var(--size) / 2);
  background: rgb(209, 209, 209);
  display: block;
  border-radius: 100px;
  position: relative;
}

.index-checkbox+label:after {
  content: "";
  position: absolute;
  top: 10%;
  left: 4.5%;
  width: calc(50% - 10%);
  height: calc(100% - 20%);
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.index-checkbox:checked+label {
  background: #6f9d2f;
}

.index-checkbox:checked+label:after {
  left: calc(100% - 4.5%);
  transform: translateX(-100%);
}

.index-checkbox+label:active:after {
  width: 55%;
}

//************ end checkbox style *************//
.index-span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  color: #7b7b7b;
}

.info-icon-wrapper {
  width: 30px;
  height: 30px;
  border: 0.5px solid #a8a8a8;
  border-radius: 3px;
  font-size: 18px;
  color: #7b7b7b;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 2px 1px #a8a8a8;
    color: #000;
  }
}

.index-tooltip {
  --bs-tooltip-opacity: 1;

  // transform: translate3d(183%, 87%, 0px) !important;
  .tooltip-arrow {
    display: none !important;
  }

  .tooltip-inner {
    background-color: #fff;
    color: #000000;
    text-align: inherit;
  }
}

.badge-soft-warning {
  color: #f1b44c !important;
  background-color: rgba(241, 180, 76, 0.18) !important;
}

.badge-soft-danger {
  color: #f46a6a !important;
  background-color: rgba(244, 106, 106, 0.18) !important;
}

.badge-soft-dark {
  color: #343a40 !important;
  background-color: rgba(52, 58, 64, 0.18) !important;
}

.badge-soft-info {
  color: #50a5f1 !important;
  background-color: rgba(80, 165, 241, 0.18) !important;
}

.badge-soft-success {
  color: #34c38f !important;
  background-color: rgba(52, 195, 143, 0.18) !important;
}

.custom-badge {
  display: inline-block !important;
  padding: 0.25em 0.4em !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1 !important;
  color: #fff;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.25rem !important;
}

.task-area-icon {
  color: #6f9d2f;
  font-size: 22px;
  height: fit-content;
  margin-right: 5px;
}

.task-area {
  text-transform: uppercase;
  font-size: 13px;
  margin-right: 10px;
}

.tree-count-icon {
  font-size: 30px;
  color: #6f9d2f;
}

.tree-number {
  font-size: 18px;
  color: #7b7b7b;
}


.sidebar-bottom-closed {
  bottom: -15vh;
}

#bottombar-toogle-button-wrapper {
  width: 35px;
  height: 35px;
  position: absolute;

  left: 50%;
  bottom: 18vh;
  z-index: 99999;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  .sidebar-open-icon {
    font-size: 20px;
  }

  .sidebar-close-icon {
    font-size: 20px;
  }
}

.leaflet-left .leaflet-control-scale {
  position: fixed;
  bottom: 1%;
  left: 26%;
  margin: 0px;
  transition: 0.5s;
  transition: all 0.5s ease;

}


@media screen and (max-width:768px) {
  .sidebar {
    width: 80%;
    top: 68px;
    max-height: 63%;
  }

  .sidebar-bottom {
    //width: 80%;
    bottom: 13.5%;
    min-height: max-content;
    align-content: center;
    justify-content: center;
  }

  .sidebar-closed {
    left: -80%;
  }

  #sidebar-toogle-button-wrapper {
    left: auto;
    right: 2%
  }

#location_input_div {
  display: flex;
  position: fixed;
  border-radius: 30px;
  bottom: 6.5%;
  left: 20%;
  width: 60%;
  z-index: 1000;
  background: white;
  // min-width:max-content;
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  height: 40px;

}
}
@media screen and (min-width:769px) {
  #location_input_div {
    display: flex;
    justify-content: space-between; /* Optional: Adjusts spacing between elements */
    align-items: center;
    position: absolute;
    top: 63px;
    right: .7%;
    border-radius: 4px;
    width: 18%;
    z-index: 1000;
    background: white;
    min-width:fit-content;
    max-width: 600px;
    border: 0;
  }
}
.tippy-box[data-theme~=light] {
  box-shadow: none !important;
}




.checkbox-on-off {
  --blue: #0D7EFF;
  --g08: #E1E5EB;
  --g04: #848ea1;
}

.checkbox-on-off .tgl {
  display: none;
}

.checkbox-on-off .tgl,
.checkbox-on-off .tgl:after,
.checkbox-on-off .tgl:before,
.checkbox-on-off .tgl *,
.checkbox-on-off .tgl *:after,
.checkbox-on-off .tgl *:before,
.checkbox-on-off .tgl+.tgl-btn {
  box-sizing: border-box;
}

.checkbox-on-off .tgl::selection,
.checkbox-on-off .tgl:after::selection,
.checkbox-on-off .tgl:before::selection,
.checkbox-on-off .tgl *::selection,
.checkbox-on-off .tgl *:after::selection,
.checkbox-on-off .tgl *:before::selection,
.checkbox-on-off .tgl+.tgl-btn::selection {
  background: none;
}

.checkbox-on-off .tgl+.tgl-btn {
  outline: 0;
  display: block;
  width: 68px;
  height: 30px;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.checkbox-on-off .tgl+.tgl-btn:after,
.checkbox-on-off .tgl+.tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 44%;
  height: 100%;
}

.checkbox-on-off .tgl+.tgl-btn:after {
  left: 0;
}

.checkbox-on-off .tgl+.tgl-btn:before {
  display: inline;
  position: absolute;
  top: 7px;
}

.checkbox-on-off .tgl:checked+.tgl-btn:after {
  left: 56.5%;
}

.checkbox-on-off .tgl-ios+.tgl-btn {
  background: #ff0000;
  border-radius: 20rem;
  padding: 2px;
  transition: all 0.6s ease;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.checkbox-on-off .tgl-ios+.tgl-btn:after {
  border-radius: 2em;
  background: #fff;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.checkbox-on-off .tgl-ios+.tgl-btn:before {
  content: "OFF";
  left: 35px;
  color: #ffffff;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-weight: 600;
}

.checkbox-on-off .tgl-ios+.tgl-btn:active {
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
}

.checkbox-on-off .tgl-ios+.tgl-btn:active:after {
  padding-right: 0.4em;
}

.checkbox-on-off .tgl-ios:checked+.tgl-btn {
  background: #60b92e;
}

.checkbox-on-off .tgl-ios:checked+.tgl-btn:active {
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
}

.checkbox-on-off .tgl-ios:checked+.tgl-btn:active:after {
  margin-left: -0.4em;
}

.checkbox-on-off .tgl-ios:checked+.tgl-btn:before {
  content: "ON";
  left: 10px;
  color: #fff;
}
.checkbox-on-off .tgl:disabled + .tgl-btn {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
}

.checkbox-man-auto {
  --blue: #0D7EFF;
  --g08: #E1E5EB;
  --g04: #848ea1;
}

.checkbox-man-auto .tgl {
  display: none;
}

.checkbox-man-auto .tgl,
.checkbox-man-auto .tgl:after,
.checkbox-man-auto .tgl:before,
.checkbox-man-auto .tgl *,
.checkbox-man-auto .tgl *:after,
.checkbox-man-auto .tgl *:before,
.checkbox-man-auto .tgl+.tgl-btn {
  box-sizing: border-box;
}

.checkbox-man-auto .tgl::selection,
.checkbox-man-auto .tgl:after::selection,
.checkbox-man-auto .tgl:before::selection,
.checkbox-man-auto .tgl *::selection,
.checkbox-man-auto .tgl *:after::selection,
.checkbox-man-auto .tgl *:before::selection,
.checkbox-man-auto .tgl+.tgl-btn::selection {
  background: none;
}

.checkbox-man-auto .tgl+.tgl-btn {
  outline: 0;
  display: block;
  width: 115px;
  height: 30px;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.checkbox-man-auto .tgl+.tgl-btn:after,
.checkbox-man-auto .tgl+.tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 26%;
  height: 100%;
}

.checkbox-man-auto .tgl+.tgl-btn:after {
  left: 0;
}

.checkbox-man-auto .tgl+.tgl-btn:before {
  display: inline;
  position: absolute;
  top: 7px;
}

.checkbox-man-auto .tgl:checked+.tgl-btn:after {
  left: 73.5%;
}

.checkbox-man-auto .tgl-ios+.tgl-btn {
  background: #349cff;
  border-radius: 20rem;
  padding: 2px;
  transition: all 0.6s ease;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.checkbox-man-auto .tgl-ios+.tgl-btn:after {
  border-radius: 2em;
  background: #fff;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.checkbox-man-auto .tgl-ios+.tgl-btn:before {
  content: "ONLINE";
  left: 44px;
  color: #ffffff;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-weight: 600;
}

.checkbox-man-auto .tgl-ios+.tgl-btn:active {
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
}

.checkbox-man-auto .tgl-ios+.tgl-btn:active:after {
  padding-right: 0.4em;
}

.checkbox-man-auto .tgl-ios:checked+.tgl-btn {
  background: #60b92e;
}

.checkbox-man-auto .tgl-ios:checked+.tgl-btn:active {
  box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
}

.checkbox-man-auto .tgl-ios:checked+.tgl-btn:active:after {
  margin-left: -0.4em;
}

.checkbox-man-auto .tgl-ios:checked+.tgl-btn:before {
  content: "OFFLINE";
  left: 8px;
  color: #fff;
}

.block-task-weather {
  cursor: pointer;
}

.sidebar-graph-legend-icon-wrapper {
  border: 2px solid #c0c0c0;
  border-radius: 3px;
  padding: 0px;
  min-width: 40px;
}

.sidebar-bottom-right {
  background-color: #fff;
  position: absolute;
  margin: 0px;
  transition: 0.5s;
  z-index: 9999;
  margin: 0px;
  transition: 0.5s;
  left: 10px;
  bottom: 10px;
  padding: 0px 0px 0px 0px;
  border-radius: 3px;
  //width: 19%;
  //height: 7vh;
  transition: all 0.5s ease;
}
